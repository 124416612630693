import React, { useRef, useState, useEffect } from "react";
import DOMPurify from "dompurify";
import "./ChatView.scss";
import FooterNavigation from "../../Components/FooterNavigation/FooterNavigation";
import HeaderNavigation from "../../Components/HeaderNavigation/HeaderNavigation";
import { useStore } from "../../utils/store";

function ChatView() {
  const challenge = useStore((state) => state.challenge);
  const footerNavigationRef = useRef(null);
  const [footerNavigationHeight, setFooterNavigationHeight] = useState(0);

  useEffect(() => {
    const updateFooterHeight = () => {
      if (footerNavigationRef.current) {
        setFooterNavigationHeight(footerNavigationRef.current.clientHeight);
      }
    };

    updateFooterHeight();
    window.addEventListener("resize", updateFooterHeight);

    return () => window.removeEventListener("resize", updateFooterHeight);
  }, []);

  return (
    <>
      <HeaderNavigation simpleNavi={true} />
      <div
        className="page-container"
        style={{
          paddingBottom: `${footerNavigationHeight}px`,
          marginTop: "60px", // Account for header
        }}
      >
        <div className="chat">
          <h1 className="chat-title">
            {challenge?.globalSettings?.chatView?.title || "CHAT"}
          </h1>
          <div
            className="chat-text"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                challenge?.globalSettings?.chatView?.content ||
                  `Wir kommunizieren über unsere Discord Gruppe.<br /><br />
                  Noch kein Mitglied? Folge jetzt diesem Einladungslink!
                  <br /><br />
                  <a class="underline" href="https://discord.com/channels/1142008218176987227/1313801717" target="_blank">
                    EINLADUNG ZUR HiLife Discord Gruppe
                  </a>`
              ),
            }}
          />
        </div>
      </div>
      <FooterNavigation ref={footerNavigationRef} />
    </>
  );
}

export default ChatView;
