import React, { useEffect, useState } from "react";
import axios from "axios";
import "./TotalRank.scss";
import { useStore } from "../../utils/store";
import ProfileIcon from "../Icons/ProfileIcon";
import { differenceInDays } from "date-fns";

const MAX_DAILY_POINTS = 4 + 3 + 3 + 2 + 2 + 2; // Sum of daily points
const MAX_WEEKLY_POINTS = 5 + 5 + 2 + 10; // Sum of weekly points

function TotalRank({ ownRankRef, footerNavigationHeight }) {
  const [rankings, setRankings] = useState([]);
  const [allRankings, setAllRankings] = useState([]);
  const userChallenge = useStore((state) => state.userChallenge);
  const userRankingData = allRankings.find(
    (rank) => rank.userId?._id?.toString() === userChallenge.user
  );
  const user = useStore((state) => state.user);
  const challenge = useStore((state) => state.challenge);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/ranking/get/ranking/${challenge._id}`,
        {
          params: {
            userId: userChallenge.user,
          },
        }
      )
      .then((response) => {
        const rankingData = response.data;
        if (Array.isArray(rankingData)) {
          setAllRankings(rankingData);
          const filteredRankings = rankingData.filter(
            (rank) =>
              !rank.hidden ||
              rank.userId?._id?.toString() === userChallenge.user
          );
          setRankings(filteredRankings);
        }
      })
      .catch((error) => {
        console.error("Error fetching rankings:", error);
      });
  }, [challenge, userChallenge.user]);

  const calculateMaxScore = (challenge) => {
    const currentDate = new Date();
    const challengeStartDate = new Date(challenge.startDate);
    const challengeEndDate = new Date(challenge.endDate);

    // Limit the effective date to the challenge's end date if current date is past it
    const effectiveDate =
      currentDate > challengeEndDate ? challengeEndDate : currentDate;

    const currentDay = differenceInDays(effectiveDate, challengeStartDate) + 1;

    const completedDays = currentDay - 1;
    const currentWeek = Math.floor(completedDays / 7);

    const maxDailyScore = completedDays * MAX_DAILY_POINTS;
    const maxWeeklyScore = currentWeek * MAX_WEEKLY_POINTS;

    const totalMaxScore = maxDailyScore + maxWeeklyScore;

    return totalMaxScore;
  };

  const maxScore = calculateMaxScore(challenge);

  return (
    <>
      <div className="totalrank lg:max-w-3xl lg:w-full lg:mx-auto lg:border-r lg:border-l">
        {rankings.length ? (
          <div className="pb-32">
            <p></p>
            {rankings.map((rank, index) => (
              <div key={index + 1}>
                <div className="totalrank-single">
                  <p className="totalrank-rank">{index + 1}</p>

                  {rank.avatar ? (
                    <img
                      src={rank.avatar}
                      alt=""
                      className="totalrank-avatar"
                    />
                  ) : (
                    <div className="totalrank-single-profile">
                      <ProfileIcon />
                    </div>
                  )}

                  <h2 className="totalrank-name font-extrabold">
                    {rank.firstName}{" "}
                    {rank.lastName ? Array.from(rank.lastName)[0] : ""}.
                  </h2>
                  <div className="totalrank-score">
                    <h4 className="totalrank-score-percent font-extrabold">
                      {rank.totalScore && rank.totalScore > 0
                        ? `${Math.round((rank.totalScore * 100) / maxScore)}%`
                        : `0%`}
                    </h4>
                    <p className="totalrank-score-points">
                      {rank.totalScore} Pkt
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-24 px-4">
            <p className="reflectionlog-empty">Das Ranking ist aktuell leer.</p>
          </div>
        )}
      </div>

      {userRankingData && (
        <div
          className="fixed w-full"
          style={{ bottom: footerNavigationHeight + "px" }}
          ref={ownRankRef}
        >
          <div className={`totalrank-self lg:max-w-3xl lg:w-full lg:mx-auto`}>
            <p className="totalrank-rank">
              {userRankingData.hidden
                ? "-" // Show dash instead of position for hidden users
                : allRankings.findIndex(
                    (rank) =>
                      rank.userId?._id?.toString() === userChallenge.user
                  ) + 1}
            </p>
            <img src={user.avatar} alt="" className="totalrank-avatar" />
            <h2 className="totalrank-name font-extrabold">
              {user.firstname}{" "}
              {user.lastname ? Array.from(user.lastname)[0] : ""}.
            </h2>
            <div className="totalrank-score">
              <h4 className="totalrank-score-percent font-extrabold">
                {userRankingData.totalScore && userRankingData.totalScore > 0
                  ? `${Math.round(
                      (userRankingData.totalScore * 100) / maxScore
                    )}%`
                  : `0%`}
              </h4>
              <p className="totalrank-score-points">
                {userRankingData.totalScore} Pkt
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TotalRank;
