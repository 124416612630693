import classNames from "classnames";
import React, { useState, useEffect } from "react";
import NourishIcon from "../../../../../Icons/NourishIcon";
import ResetIcon from "../../../../../Icons/ResetIcon";
import "./styles/nourish-modal.scss";

function NourishModal({
  setOpen,
  habit,
  updateHabit,
  userChallenge,
  currentDay,
}) {
  const [checkboxes, setCheckboxes] = useState({
    fruits: false,
    proteins: false,
    sugar: false,
  });

  // Initialize checkboxes from database
  useEffect(() => {
    console.log("=== Nourish Modal Init ===");
    console.log("Current Day:", currentDay);
    console.log("UserChallenge:", userChallenge);
    const nourishEntry = userChallenge.dailyHabits.nourish.find(
      (entry) => entry.day === currentDay
    );
    console.log("Found Nourish Entry:", nourishEntry);

    if (nourishEntry && nourishEntry.checks) {
      console.log("Setting checkboxes to:", nourishEntry.checks);
      setCheckboxes(nourishEntry.checks);
    }
  }, [currentDay, userChallenge.dailyHabits.nourish]);

  const isHabitChecked = () => {
    const nourishEntry = userChallenge.dailyHabits.nourish.find(
      (entry) => entry.day === currentDay
    );
    console.log("isHabitChecked - Entry:", nourishEntry);
    return nourishEntry && nourishEntry.points === 6;
  };

  const calculatePoints = () => {
    const checkedCount = Object.values(checkboxes).filter(Boolean).length;
    console.log("Calculating points - Checked boxes:", checkboxes);
    console.log("Total points:", checkedCount * 2);
    return checkedCount * 2;
  };

  const handleCheckboxChange = (key) => {
    console.log("Checkbox changed:", key);
    const newCheckboxes = {
      ...checkboxes,
      [key]: !checkboxes[key],
    };
    console.log("New checkbox state:", newCheckboxes);
    setCheckboxes(newCheckboxes);
  };

  const handleSave = () => {
    const points = calculatePoints();

    updateHabit(points, "nourish", { checks: checkboxes });
    setOpen(false);
  };

  const handleReset = () => {
    console.log("=== Resetting Nourish Data ===");
    const resetData = {
      checks: {
        fruits: false,
        proteins: false,
        sugar: false,
      },
    };
    console.log("Reset data:", resetData);
    updateHabit(0, "nourish", resetData);
    setOpen(false);
  };

  return (
    <>
      {!isHabitChecked() ? (
        <div className="w-full">
          <div className="w-8 w-8 mx-auto mb-4">
            <NourishIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">ERNÄHRUNG</h1>

          <h5 className="text-sm mt-4 mb-6 font-extrabold text-center">
            Hast Du heute
          </h5>

          <div className="flex flex-col gap-4 px-4 mt-12">
            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={checkboxes.fruits}
                onChange={() => handleCheckboxChange("fruits")}
              />
              <span className="checkbox-mark"></span>
              <span className="checkbox-label">
                ausreichend* Obst oder Gemüse gegessen? (2 Punkte)
              </span>
            </label>

            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={checkboxes.proteins}
                onChange={() => handleCheckboxChange("proteins")}
              />
              <span className="checkbox-mark"></span>
              <span className="checkbox-label">
                ausreichend* Proteine gegessen? (2 Punkte)
              </span>
            </label>

            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={checkboxes.sugar}
                onChange={() => handleCheckboxChange("sugar")}
              />
              <span className="checkbox-mark"></span>
              <span className="checkbox-label">
                keinen zugesetzten Zucker / Alkohol verzehrt? (2 Punkte)
              </span>
            </label>
          </div>

          <p className="text-xs text-center mt-6 mb-8 text-gray-400">
            *welche Mengen "ausreichend" sind, kannst Du in unseren
            Ernährungsregeln nachlesen.
          </p>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={handleSave}
            disabled={Object.values(checkboxes).every((v) => !v)}
          >
            FERTIG ({calculatePoints()} Punkte)
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen("keepup")}
          >
            ABBRECHEN
          </button>
        </div>
      ) : (
        <div className="w-full text-center">
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl">PUNKTE WIDERRUFEN</h1>
          <h3 className="mt-4 text-sm mb-5">
            Hier kannst du deine Punkte zurücksetzen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={handleReset}
          >
            PUNKTE WIDERRUFEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4 pb-8"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      )}
    </>
  );
}

export default NourishModal;
