import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "./TeamInvite.scss";
import { useStore } from "../../utils/store";

function TeamInvite() {
  const { identifier } = useParams();
  const [teamData, setTeamData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const setUserChallenge = useStore((state) => state.setUserChallenge);
  const setUser = useStore((state) => state.setUser);

  useEffect(() => {
    const isRedirectAfterLogin = localStorage.getItem("redirectAfterLogin");

    if (!user || !user._id) {
      localStorage.setItem("redirectAfterLogin", `/teams/${identifier}`);
      navigate("/login");
      return;
    }

    // Clear the redirect flag if we're logged in
    if (isRedirectAfterLogin) {
      localStorage.removeItem("redirectAfterLogin");
    }

    const fetchTeamData = async () => {
      try {
        setIsLoading(true);
        const teamResponse = await axios.get(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/team/by-identifier/${identifier}`
        );

        if (!teamResponse.data) {
          toast.error("Team nicht gefunden.");
          navigate("/challenge-dashboard");
          return;
        }

        setTeamData(teamResponse.data);

        // Check if user is already in a team
        const userChallengeResponse = await axios.post(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/register/get/user/challenge`,
          {
            userId: user._id,
            challengeId: teamResponse.data.challenge._id,
          }
        );

        if (userChallengeResponse.data?.team) {
          toast.info("Du bist bereits in einem Team.");
          navigate("/team");
          return;
        }

        // If we got here after login, automatically join the team
        if (isRedirectAfterLogin) {
          handleJoin();
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(
          "Ein Fehler ist aufgetreten. Bitte versuche es später erneut."
        );
        navigate("/challenge-dashboard");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamData();
  }, [identifier, user, navigate]);

  const handleJoin = async () => {
    try {
      const response = await axios.post(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/team/add-member/${teamData._id}/challenge/${teamData.challenge._id}`,
        {
          userId: user._id,
        }
      );

      if (response.status === 200) {
        toast.success("Du bist dem Team erfolgreich beigetreten!");

        const updatedUserChallenge = response.data.userChallenge;
        if (updatedUserChallenge) {
          setUserChallenge(updatedUserChallenge);
          setUser((user) => ({
            ...user,
            challenge: updatedUserChallenge,
          }));
        }
        navigate("/team");
      } else {
        toast.error(
          "Beitritt zum Team fehlgeschlagen. Bitte versuche es erneut."
        );
      }
    } catch (error) {
      console.error("Error joining the team:", error);
      toast.error(
        "Beim Beitritt zum Team ist ein Fehler aufgetreten. Bitte versuche es erneut."
      );
    }
  };

  const handleDecline = () => {
    navigate("/challenge-dashboard");
  };

  if (isLoading) {
    return <div className="teamInvite">Loading...</div>;
  }

  return (
    <div className="teamInvite">
      {teamData ? (
        <>
          <h1>Einladung zu einem Team</h1>
          <p>
            Das Team <b>{teamData.teamName}</b> hat dich eingeladen, möchtest du
            dem Team beitreten?
          </p>
          <button className="teamInvite-yes" onClick={handleJoin}>
            Ja
          </button>
          <button className="teamInvite-no" onClick={handleDecline}>
            Nein
          </button>
        </>
      ) : (
        <p>Team nicht gefunden.</p>
      )}
    </div>
  );
}

export default TeamInvite;
