import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import {
  FaCalendarAlt,
  FaSave,
  FaEdit,
  FaTrash,
  FaClock,
  FaEuroSign,
  FaImage,
  FaTimes,
  FaToggleOn,
  FaToggleOff,
  FaCog,
} from "react-icons/fa";
import "./ChallengeSettings.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useToast } from "../Toast/ToastManager";
import Dialog from "../Dialog/Dialog";
import ChallengeGlobalSettings from "../ChallengeGlobalSettings/ChallengeGlobalSettings";

function ChallengeSettings() {
  const [challenges, setChallenges] = useState([]);
  const [editingChallenge, setEditingChallenge] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Form states
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  const { showToast, ToastContainer, onClose } = useToast();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [challengeToDelete, setChallengeToDelete] = useState(null);

  const [showGlobalSettings, setShowGlobalSettings] = useState(false);
  const [globalSettings, setGlobalSettings] = useState({
    communityChat: {
      subline: "",
      text: "",
      link: "",
    },
  });

  const handleGlobalSettingsChange = (newSettings) => {
    setGlobalSettings(newSettings);
  };

  useEffect(() => {
    fetchChallenges();
  }, []);

  const fetchChallenges = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/challenge`
      );
      const sortedChallenges = response.data.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      );
      setChallenges(sortedChallenges);
    } catch (error) {
      console.error(error);
      showToast("Failed to fetch challenges", "error");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const resetForm = () => {
    setName("");
    setStartDate("");
    setDuration("");
    setPrice("");
    setImage(null);
    setImagePreview("");
    setEditingChallenge(null);
    setIsEditing(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("startDate", startDate);
    formData.append("duration", duration);
    formData.append("price", price);
    if (image) formData.append("challengeImage", image);
    formData.append("globalSettings", JSON.stringify(globalSettings));

    const loadingToastId = showToast("Saving challenge...", "loading", null);

    try {
      if (editingChallenge) {
        await axios.put(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/challenge/${editingChallenge._id}`,
          formData
        );
        onClose(loadingToastId);
        showToast("Challenge updated successfully!", "success");
      } else {
        await axios.post(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/challenge`,
          formData
        );
        onClose(loadingToastId);
        showToast("Challenge created successfully!", "success");
      }
      resetForm();
      fetchChallenges();
    } catch (error) {
      console.error(error);
      onClose(loadingToastId);
      showToast("Failed to save challenge", "error");
    }
  };

  const startEditing = (challenge) => {
    setEditingChallenge(challenge);
    setName(challenge.name);
    setStartDate(new Date(challenge.startDate));
    setDuration(challenge.duration);
    setPrice(challenge.price);
    setImagePreview(challenge.image);
    setGlobalSettings({
      ...(challenge.globalSettings || {
        communityChat: {
          title: "COMMUNITY APP",
          subtitle:
            "Klicke auf den Link und werde Teil unserer Community Chat Gruppe",
          content: "",
        },
      }),
      challengeId: challenge._id,
    });
    setIsEditing(true);
  };

  const handleDeleteClick = (id) => {
    setChallengeToDelete(id);
    setDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (challengeToDelete) {
      try {
        await axios.delete(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/challenge/${challengeToDelete}`
        );
        showToast("Challenge deleted successfully!", "success");
        fetchChallenges();
      } catch (error) {
        console.error(error);
        showToast("Failed to delete challenge", "error");
      }
      setDialogOpen(false);
      setChallengeToDelete(null);
    }
  };

  const toggleChallengeActive = async (id, currentStatus) => {
    try {
      await axios.put(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/challenge/toggle/${id}`
      );
      fetchChallenges();
      showToast(
        `Challenge ${
          currentStatus ? "deactivated" : "activated"
        } successfully!`,
        "success"
      );
    } catch (error) {
      console.error(error);
      showToast("Failed to toggle challenge status", "error");
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  return (
    <div className="challenge-settings">
      <ToastContainer />
      <Dialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Delete Challenge"
        message="Are you sure you want to delete this challenge? This action cannot be undone."
        confirmText="Delete"
        cancelText="Cancel"
        type="danger"
      />
      <div className="settings-header">
        <h2>Challenge Settings</h2>
        <div className="header-actions">
          <button
            className="create-button"
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ? <FaTimes /> : "Create Challenge"}
          </button>
        </div>
      </div>

      {isEditing && (
        <form className="challenge-form" onSubmit={handleSubmit}>
          <div className="form-header">
            <h3>
              {editingChallenge ? "Edit Challenge" : "Create New Challenge"}
            </h3>
          </div>

          <div className="form-content">
            <div className="form-group">
              <label>Challenge Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>
                  <FaCalendarAlt />
                  Start Date
                </label>
                <DatePicker
                  selected={startDate ? new Date(startDate) : null}
                  onChange={(date) => setStartDate(date.toISOString())}
                  className="date-picker-input"
                  dateFormat="dd.MM.yyyy"
                  placeholderText="Select start date"
                  required
                />
              </div>

              <div className="form-group">
                <label>Duration (weeks)</label>
                <input
                  type="number"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label>Price (€)</label>
                <input
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label>Challenge Image</label>
              <div className="image-upload">
                <input
                  type="file"
                  onChange={handleImageChange}
                  accept="image/*"
                  id="challenge-image"
                />
                <label htmlFor="challenge-image" className="upload-label">
                  <FaImage /> Choose Image
                </label>
                {imagePreview && (
                  <div className="image-preview">
                    <img src={imagePreview} alt="Preview" />
                  </div>
                )}
              </div>
            </div>

            {editingChallenge && (
              <div className="form-group">
                <button
                  type="button"
                  className="global-settings-toggle"
                  onClick={() => setShowGlobalSettings(!showGlobalSettings)}
                >
                  <FaCog /> More Settings
                </button>

                {showGlobalSettings && (
                  <ChallengeGlobalSettings
                    globalSettings={globalSettings}
                    onSettingsChange={handleGlobalSettingsChange}
                  />
                )}
              </div>
            )}

            <div className="form-actions">
              <button type="submit" className="save-button">
                <FaSave /> {editingChallenge ? "Update" : "Create"}
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={resetForm}
              >
                <FaTimes /> Cancel
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="challenges-grid">
        {challenges.map((challenge) => (
          <div key={challenge._id} className="challenge-card">
            <div className="challenge-image">
              <img src={challenge.image} alt={challenge.name} />
              <div className="challenge-status">
                {new Date(challenge.endDate) < new Date() ? (
                  <span className="status ended">Ended</span>
                ) : (
                  <span
                    className={`status ${
                      challenge.active ? "active" : "inactive"
                    }`}
                  >
                    {challenge.active ? "Active" : "Inactive"}
                  </span>
                )}
              </div>
            </div>

            <div className="challenge-content">
              <div className="challenge-status-wrapper">
                <div className="status-indicator">
                  {new Date(challenge.endDate) < new Date() ? (
                    <>
                      <div className="status-dot ended" />
                      <span>Ended</span>
                    </>
                  ) : (
                    <>
                      <div
                        className={`status-dot ${
                          challenge.active ? "active" : "inactive"
                        }`}
                      />
                      <span>{challenge.active ? "Active" : "Inactive"}</span>
                    </>
                  )}
                </div>
              </div>

              <h3>{challenge.name}</h3>

              <div className="challenge-details">
                <div className="detail">
                  <span className="date">
                    <FaCalendarAlt />
                    {formatDate(challenge.startDate)}
                  </span>
                </div>
                <div className="detail">
                  <FaClock />
                  <span>{challenge.duration} weeks</span>
                </div>
                <div className="detail">
                  <FaEuroSign />
                  <span>{challenge.price}€</span>
                </div>
              </div>

              <div className="challenge-actions">
                <button
                  className="edit-button"
                  onClick={() => startEditing(challenge)}
                  data-tooltip="Edit Challenge"
                >
                  <FaEdit />
                </button>
                <button
                  className={`toggle-button ${
                    challenge.active ? "active" : ""
                  }`}
                  onClick={() =>
                    toggleChallengeActive(challenge._id, challenge.active)
                  }
                  data-tooltip={`${
                    challenge.active ? "Deactivate" : "Activate"
                  } Challenge`}
                >
                  {challenge.active ? <FaToggleOn /> : <FaToggleOff />}
                  <span>{challenge.active ? "Active" : "Inactive"}</span>
                </button>
                <button
                  className="delete-button"
                  onClick={() => handleDeleteClick(challenge._id)}
                  data-tooltip="Delete Challenge"
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChallengeSettings;
