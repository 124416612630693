import React, { useState, useEffect } from "react";
import axios from "axios";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { getTime } from "date-fns";
import logo from "./img/logo.svg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Countdown from "../Countdown/Countdown";
import Footer from "../Footer/Footer";

import "./Home.scss";

import CheckIconHome from "../Icons/CheckIconHome";
import DiceIcon from "../Icons/DiceIcon";
import GroupIcon from "../Icons/GroupIcon";
import RulesHomeIcon from "../Icons/RulesHomeIcon";
import ScienceIcon from "../Icons/ScienceIcon";
import NourishIcon from "../Icons/NourishIcon";
import TrainingIcon from "../Icons/TrainingIcon";
import SleepIcon from "../Icons/SleepIcon";
import ShowerIcon from "../Icons/ShowerIcon";
import BreathIcon from "../Icons/BreathIcon";
import GratitudeIcon from "../Icons/GratitudeIcon";
import ActionIcon from "../Icons/ActionIcon";
import NatureIcon from "../Icons/NatureIcon";
import ReflectionIcon from "../Icons/ReflectionIcon";

import ready from "./img/ready.png";
import header from "./img/header.png";
import headerMobile from "./img/headerMobile.png";

import helpyou from "./img/helpyou.png";
import aboutDesktop from "./img/aboutDesktop.png";
import ProfileHomepageIcon from "../Icons/ProfileHomepageIcon";
import RulesIconHomepage from "../Icons/RulesIconHomepage";

import marcel from "./img/marcel.png";
import natalia from "./img/natalia.png";
import heiner from "./img/heiner.png";
import paul from "./img/paul.png";
import björn from "./img/björn.png";
import bernice from "./img/bernice.png";
import andrea from "./img/andrea.png";
import christina from "./img/christina.png";

import leon from "./img/leon.png";
import patrick from "./img/patrick.png";
import slatco from "./img/slatco.png";
import vidya from "./img/vidya.png";
import ulrich from "./img/ulrich.png";
import jacob from "./img/jacob.png";
import johannes from "./img/johannes.png";
import dirk from "./img/dirk.png";
import matthias from "./img/matthias.png";
import josephine from "./img/josephine.png";

import mens from "./img/mens.png";
import spiegel from "./img/spiegel.png";
import deutschlandfunk from "./img/deutschlandfunk.png";
import deutschwelle from "./img/deutschwelle.png";
import engelvoelker from "./img/engelvoelker.png";
import vice from "./img/vice.png";
import weltbrand from "./img/weltbrand.png";
import achilles from "./img/achilles.png";

import app from "./img/app.png";
import HiLifeLogo from "../Icons/HiLifeLogo";
import Score from "../Score";

import albrecht from "./img/albrecht.png";
import patrickheizmann from "./img/patrickheizmann.png";

function Home() {
  const [homepage, setHomepage] = useState({});
  const [activeChallenge, setActiveChallenge] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const [scoreRef, scoreInView] = useInView({
    triggerOnce: true,
    threshold: 1,
  });
  const slideInFromLeft = {
    hidden: { opacity: 0, x: "-100%" },
    show: { opacity: 1, x: "0%" },
  };
  const fadeIn = {
    hidden: { opacity: 0, display: "none" },
    show: { opacity: 1, display: "block" },
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchHomepageData = async () => {
      try {
        const res = await axios.get(
          `${
            process.env.NODE_ENV === "production"
              ? "api"
              : "http://localhost:5001/api"
          }/homepage`
        );
        setHomepage(res.data);
      } catch (error) {
        console.error(`Error fetching homepage data: ${error}`);
      }
    };

    const fetchChallengesData = async () => {
      try {
        const res = await axios.get(
          `${
            process.env.NODE_ENV === "production"
              ? "api"
              : "http://localhost:5001/api"
          }/challenge`
        );

        const currentDate = new Date();
        const activeChallenge = res.data.find((challenge) => {
          const startDate = new Date(challenge.startDate);
          return challenge.active && startDate > currentDate;
        });

        setActiveChallenge(activeChallenge);
      } catch (error) {
        console.error(`Error fetching challenges data: ${error}`);
      }
    };

    // Use Promise.all to wait for both fetches to complete
    Promise.all([fetchHomepageData(), fetchChallengesData()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  function LoadingAnimation() {
    return (
      <div className="loading-container">
        <HiLifeLogo color={homepage.textColorModul} />
      </div>
    );
  }

  return (
    <div className="page-container">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="home pb-24">
          <header className="flex items-center fixed top-0 z-50 justify-between w-full px-6">
            <div className="flex-auto">
              <div className="mx-auto fill-white w-24 h-24 lg:w-32 lg:h-32 home-logo-container">
                <HiLifeLogo color={homepage.logoColor} />
              </div>
            </div>
            <a href="/login">
              <ProfileHomepageIcon color={homepage.loginColor} />
            </a>
          </header>

          <section className="relative flex flex-col justify-center aspect-[1/1.45] lg:aspect-video lg:max-h-[80vh] w-full bg-beige">
            <img
              alt=""
              src={homepage.heroImage || header}
              className="absolute top-0 left-0 w-full h-full object-cover hidden lg:block"
            />
            <img
              alt=""
              src={homepage.heroImage || headerMobile} // Assuming you want to use the same image for mobile
              className="absolute top-0 left-0 w-full h-full object-cover lg:hidden"
            />

            <h1
              style={{
                color: homepage.heroTextColor,
              }}
              className="home-hero-title"
            >
              {homepage.heroText}
            </h1>
            <h2
              style={{
                color: homepage.heroTextColor,
              }}
              className="relative z-10 text-center text-lg lg:text-4xl font-bold text-white lg:mb-10 px-6"
            >
              {homepage.heroSubline}
            </h2>
            <button
              className="relative z-10 w-full block font-extrabold py-4 px-8 rounded-full w-fit mx-auto mt-8"
              style={{
                backgroundColor: homepage.heroButtonColor,
                color: homepage.heroButtonTextColor,
              }}
              onClick={() => (window.location = "/register")}
            >
              {homepage.heroButtonText}
            </button>
          </section>
          <section className="bg-beige pb-1">
            <h3 className="uppercase font-extrabold text-center text-3xl pt-8 mb-2 px-6">
              NÄCHSTE CHALLENGE
            </h3>

            {activeChallenge ? (
              <>
                <p className="text-center mb-6 px-6">
                  {`${new Date(
                    activeChallenge.startDate
                  ).toLocaleDateString()} bis ${new Date(
                    activeChallenge.endDate
                  ).toLocaleDateString()}`}
                </p>

                <Countdown
                  countdownTimestampMs={getTime(
                    new Date(activeChallenge.startDate)
                  )}
                  parentClasses={"lg:max-w-screen-md mx-auto"}
                  numberClasses={"lg:text-8xl"}
                  textClasses={"lg:text-lg lg:uppercase"}
                />
              </>
            ) : (
              <p className="text-center mb-4 px-6 text-xl">
                Wird bald angekündigt
              </p>
            )}
          </section>

          <section>
            <h3 className="uppercase font-extrabold text-center text-3xl pt-8 mb-6">
              DU WILLST ...
            </h3>
            <div className="bg-beige flex p-6 mb-4">
              <div className="flex gap-4 max-w-lg items-center mx-auto">
                <p className="flex-1 text-sm md:text-lg">
                  <b>DEINEN KÖRPER TRANSFORMIEREN:</b> Fett abbauen,
                  athletischer aussehen und Dich wohl in Deinem Körper fühlen
                </p>
                <CheckIconHome />
              </div>
            </div>
            <div className="bg-beige flex p-6 mb-4">
              <div className="flex gap-4 max-w-lg items-center mx-auto">
                <p className="flex-1 text-sm md:text-lg">
                  <b>FITNESS AUFBAUEN:</b> Leistungsfähigkeit, Ausdauer und
                  Kraft steigern für mehr Energie im Alltag und Beruf
                </p>
                <CheckIconHome />
              </div>
            </div>
            <div className="bg-beige flex p-6 mb-4">
              <div className="flex gap-4 max-w-lg items-center mx-auto">
                <p className="flex-1 text-sm md:text-lg">
                  <b>MENTALE STÄRKE:</b> bessere Laune, mehr Gelassenheit und
                  weniger Stress im Alltag
                </p>
                <CheckIconHome />
              </div>
            </div>
            <div className="bg-beige flex p-6">
              <div className="flex gap-4 max-w-lg items-center mx-auto">
                <p className="flex-1 text-sm md:text-lg">
                  <b>GESUNDHEIT 2.0:</b> ganzheitliche Transformation für ein
                  gesundes, langes Leben und Wohlbefinden
                </p>
                <CheckIconHome />
              </div>
            </div>
          </section>

          <section className="pb-6 pt-6 our-community">
            <h3 className="uppercase font-extrabold mb-6 text-3xl px-6 text-center">
              DAS SAGEN DIE SPIELENDEN
            </h3>
            <p className="max-w-lg mx-auto text-center mb-6 lg:mb-12 px-6 text-sm md:text-base">
              Wir könnten den ganzen Tag erzählen, wie toll die HiLife Challenge
              ist. Aber wir sind ja etwas voreingenommen.. <br />
              Also lassen wir unsere Community sprechen:
            </p>

            <Swiper
              spaceBetween={20}
              slidesPerView={"auto"}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              navigation
              pagination={{ clickable: true }}
            >
              <SwiperSlide className="mb-10">
                <img src={andrea} alt="Andrea" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">Andrea</p>
                <p className="text-center mb-2 text-sm">Psychologin</p>
                <p className="text-center text-sm">
                  "Eine großartige Challenge: Herausfordernd und dabei
                  unglaublich motivierend. Hat bei mir wie eine Kur gewirkt,
                  sowohl auf körperlicher als auch seelischer Ebene"
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-10">
                <img src={bernice} alt="Bernice" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">Bernice</p>
                <p className="text-center mb-2 text-sm">Lehrerin</p>
                <p className="text-center text-sm">
                  "Teamgeist macht vieles möglich. 4 Wochen HiLife Challenge
                  haben viel Spaß gemacht. Danke!"
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-10">
                <img src={björn} alt="Björn" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">Björn</p>
                <p className="text-center mb-2 text-sm">Portfoliomanager</p>
                <p className="text-center text-sm">
                  "Mit wenig Einsatz so viel Ertrag. So glücklich, zentriert und
                  körperlich fit habe ich mich schon lange nicht mehr gefühlt!"
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-10">
                <img src={paul} alt="Paul" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">Paul</p>
                <p className="text-center mb-2 text-sm">
                  CEO und Founder Immobilienunternehmen
                </p>
                <p className="text-center text-sm">
                  "Der Team-Spirit während der Challenge hat sehr motiviert und
                  obwohl alles remote stattgefunden hat, kam ein wirkliches
                  Wir-Gefühl auf. Das ist echt außergewöhnlich."
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-10">
                <img src={heiner} alt="Heiner" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">Heiner</p>
                <p className="text-center mb-2 text-sm">Geschäftsführer</p>
                <p className="text-center text-sm">
                  "Die Challenge hat mich als Sportler auf ein neues Level
                  gehoben. Die ganzheitliche Betrachtung meiner Ernährung, Sport
                  und meiner Psyche haben mir eröffnet wie sehr alles
                  miteinander verbunden ist."
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-10">
                <img src={natalia} alt="Natalia" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">Natalia</p>
                <p className="text-center mb-2 text-sm">
                  Wirtschaftsjuristin und Beraterin im Gesundheitsmanagement
                </p>
                <p className="text-center text-sm">
                  "Niemals hätte ich gedacht, dass ich das alles schaffe. Diese
                  Challenge und das Teamgefühl haben mich nicht nur ein Stück
                  weit durch die harte Zeit des Lockdowns gebracht, sie haben
                  mich weit über meine Grenzen hinauswachsen lassen."
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-10">
                <img src={marcel} alt="Marcel" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">Marcel</p>
                <p className="text-center mb-2 text-sm">
                  Vertriebsprofi im Gesundheitswesen
                </p>
                <p className="text-center text-sm">
                  "Mindblowing! Ich war überwältigt, wozu mein Geist und Körper
                  in der kurzen Zeit fähig ist und wie positiv sich das auf
                  jeden Tag auswirkt. Die Workshops/Artikel haben darüber das
                  Wissen ungemein bereichert."
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-10">
                <img src={christina} alt="Christina" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">Christina</p>
                <p className="text-center mb-2 text-sm">
                  Immobilienverwalterin
                </p>
                <p className="text-center text-sm">
                  "Eine großartige Challenge, bei der man eine Menge über sich
                  lernt: das Leben auf einfache Weise zu verändern, den
                  Schweinehund zu besiegen und die Erkenntnis, dass man im/als
                  Team alles schaffen kann!"
                </p>
              </SwiperSlide>
            </Swiper>
          </section>

          <section className="flex items-center text-pink flex-col justify-center text-2xl sm:text-4xl lg:text-9xl font-extrabold relative aspect-[21/10] px-4 sm:px-6 max-h-[550px] w-full mt-8">
            <img
              alt=""
              src={homepage.imageModul || helpyou}
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
            <h3
              style={{
                color: homepage.textColorModul,
              }}
              className="relative z-10 text-center"
            >
              {homepage.textModul1}
            </h3>
            <h3
              className="relative z-10 text-center"
              style={{
                color: homepage.textColorModul,
              }}
            >
              {homepage.textModul2}
            </h3>
            <h3
              className="relative z-10 text-center"
              style={{
                color: homepage.textColorModul,
              }}
            >
              {homepage.textModul3}
            </h3>
          </section>

          <section className="bg-beige flex p-6 mb-12">
            <p className="max-w-2xl mx-auto text-center lg:text-xl font-extrabold">
              Mit der HiLife Challenge helfen wir Dir, neue Gewohnheiten
              aufzubauen und auch wirklich durchzuhalten. Damit Du besser
              gelaunt, gesünder und fitter bist als zuvor.
            </p>
          </section>

          <section className="mb-12 how-it-works">
            <h3 className="text-3xl px-6 uppercase text-center font-extrabold mb-6 lg:mb-12">
              SO FUNKTIONIERT'S
            </h3>
            <div className="mb-8">
              <Swiper
                spaceBetween={20}
                slidesPerView={"auto"}
                centeredSlides
                breakpoints={{
                  1300: {
                    centerInsufficientSlides: true,
                    centeredSlides: false,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="bg-beige aspect-square rounded-lg flex flex-col px-6 py-8 text-center justify-center">
                    <span className="flex justify-center mb-4">
                      <DiceIcon />
                    </span>
                    <p className="font-extrabold mb-2">
                      ES IST <br /> EIN SPIEL
                    </p>
                    <p className="text-sm">
                      Sammle Punkte für gesunde Gewohnheiten
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="bg-beige aspect-square rounded-lg flex flex-col px-6 py-8 text-center justify-center">
                    <span className="flex justify-center mb-4">
                      <RulesHomeIcon />
                    </span>
                    <p className="font-extrabold mb-2">
                      EINFACHE <br /> REGELN
                    </p>
                    <p className="text-sm">Kleine Habits mit großer Wirkung</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="bg-beige aspect-square rounded-lg flex flex-col px-6 py-8 text-center justify-center">
                    <span className="flex justify-center mb-4">
                      <ScienceIcon />
                    </span>
                    <p className="font-extrabold mb-2">
                      WISSENSCHAFTLICH FUNDIERT
                    </p>
                    <p className="text-sm">
                      Die Challenge basiert auf der aktuellen Wissenschaft
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="bg-beige aspect-square rounded-lg flex flex-col px-6 py-8 text-center justify-center">
                    <span className="flex justify-center mb-4">
                      <GroupIcon />
                    </span>
                    <p className="font-extrabold mb-2">
                      MIT <br /> DEINER COMMUNITY
                    </p>
                    <p className="text-sm">
                      Du spielst nicht allein! Nutze die Motivation und Dynamik
                      der HiLife Community.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <button
              className="relative z-10 bg-pink w-full block text-white font-extrabold py-4 px-8 rounded-full w-fit mx-auto"
              onClick={() => (window.location = "/register")}
              style={{
                backgroundColor: homepage.globalButtonColor,
                color: homepage.globalButtonTextColor,
              }}
            >
              {homepage.globalButtonText}
            </button>
          </section>

          <section className="bg-beige py-12 px-6 mb-12" ref={ref}>
            <h3 className="uppercase font-extrabold mb-2 text-3xl text-center">
              DARUM GEHT'S!
            </h3>
            <p className="font-light text-center mb-6 text-sm md:text-base">
              Optimiere deine Gewohnheiten in diesen Bereichen:
            </p>

            <div
              className="flex flex-wrap justify-center w-fit mx-auto gap-6"
              initial="hidden"
              animate={inView ? "show" : "hidden"}
              variants={slideInFromLeft}
              transition={{ duration: 1 }}
            >
              <div className="flex flex-col mb-6">
                <h3 className="text-xl uppercase font-extrabold text-center mb-6">
                  DAILY HABITS
                </h3>
                <motion.div
                  className="flex gap-4 mb-6 items-center"
                  animate={inView ? "show" : "hidden"}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                >
                  <div
                    style={{
                      backgroundColor: homepage.habitBackgroundColor,
                      borderRadius: "50%",
                      padding: ".25rem",
                    }}
                  >
                    <NourishIcon color={homepage.habitIconColor} />
                  </div>

                  <p className="text-sm max-w-[40ch]">
                    <span className="font-extrabold mb-1 block uppercase text-base">
                      ERNÄHRUNG
                    </span>
                    Halte Dich täglich an 3 einfache Ernährungsregeln.
                  </p>
                </motion.div>
                <motion.div
                  className="flex gap-4 mb-6 items-center"
                  animate={inView ? "show" : "hidden"}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                >
                  <div
                    style={{
                      backgroundColor: homepage.habitBackgroundColor,
                      borderRadius: "50%",
                      padding: ".25rem",
                    }}
                  >
                    <TrainingIcon color={homepage.habitIconColor} />
                  </div>
                  <p className="text-sm max-w-[40ch]">
                    <span className="font-extrabold mb-1 block uppercase text-base">
                      BEWEGUNG
                    </span>
                    Bewege Dich täglich ca. 15 Minuten mit Intention.
                  </p>
                </motion.div>
                <motion.div
                  className="flex gap-4 mb-6 items-center"
                  animate={inView ? "show" : "hidden"}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                >
                  <div
                    style={{
                      backgroundColor: homepage.habitBackgroundColor,
                      borderRadius: "50%",
                      padding: ".25rem",
                    }}
                  >
                    <SleepIcon color={homepage.habitIconColor} />
                  </div>
                  <p className="text-sm max-w-[40ch]">
                    <span className="font-extrabold mb-1 block uppercase text-base">
                      SCHLAF
                    </span>
                    Schlafe täglich 7-9 Stunden.
                  </p>
                </motion.div>
                <motion.div
                  className="flex gap-4 mb-6 items-center"
                  animate={inView ? "show" : "hidden"}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                >
                  <div
                    style={{
                      backgroundColor: homepage.habitBackgroundColor,
                      borderRadius: "50%",
                      padding: ".25rem",
                    }}
                  >
                    <ShowerIcon color={homepage.habitIconColor} />
                  </div>
                  <p className="text-sm max-w-[40ch]">
                    <span className="font-extrabold mb-1 block uppercase text-base">
                      HITZE UND KÄLTE
                    </span>
                    Setze Deinen Körper täglich Hitze oder Kälte aus.
                  </p>
                </motion.div>
                <motion.div
                  className="flex gap-4 mb-6 items-center"
                  animate={inView ? "show" : "hidden"}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                >
                  <div
                    style={{
                      backgroundColor: homepage.habitBackgroundColor,
                      borderRadius: "50%",
                      padding: ".25rem",
                    }}
                  >
                    <BreathIcon color={homepage.habitIconColor} />
                  </div>
                  <p className="text-sm max-w-[40ch]">
                    <span className="font-extrabold mb-1 block uppercase text-base">
                      ATMUNG
                    </span>
                    Konzentriere Dich täglich 5 Minuten lang auf Deine Atmung.
                  </p>
                </motion.div>
                <motion.div
                  className="flex gap-4 items-center"
                  animate={inView ? "show" : "hidden"}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                >
                  <div
                    style={{
                      backgroundColor: homepage.habitBackgroundColor,
                      borderRadius: "50%",
                      padding: ".25rem",
                    }}
                  >
                    <GratitudeIcon color={homepage.habitIconColor} />
                  </div>
                  <p className="text-sm max-w-[40ch]">
                    <span className="font-extrabold mb-1 block uppercase text-base">
                      DANKBARKEIT
                    </span>
                    Notiere täglich 3 Dinge, für die Du dankbar bist.
                  </p>
                </motion.div>
              </div>

              <div className="flex flex-col mb-6">
                <h3 className="text-xl uppercase font-extrabold text-center mb-6">
                  WEEKLY HABITS
                </h3>
                <motion.div
                  className="flex gap-4 mb-6 items-center"
                  animate={inView ? "show" : "hidden"}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                >
                  <div
                    style={{
                      backgroundColor: homepage.habitBackgroundColor,
                      borderRadius: "50%",
                      padding: ".25rem",
                    }}
                  >
                    <NatureIcon color={homepage.habitIconColor} />
                  </div>
                  <p className="text-sm max-w-[40ch]">
                    <span className="font-extrabold mb-1 block uppercase text-base">
                      NATUR
                    </span>
                    Verbringe jede Woche 120 Minuten in der Natur.
                  </p>
                </motion.div>
                <motion.div
                  className="flex gap-4 mb-6 items-center"
                  animate={inView ? "show" : "hidden"}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                >
                  <div
                    style={{
                      backgroundColor: homepage.habitBackgroundColor,
                      borderRadius: "50%",
                      padding: ".25rem",
                    }}
                  >
                    <ActionIcon color={homepage.habitIconColor} />
                  </div>
                  <p className="text-sm max-w-[40ch]">
                    <span className="font-extrabold mb-1 block uppercase text-base">
                      ACTION
                    </span>
                    Werde aktiv und stell Dich spannenden Aufgaben.
                  </p>
                </motion.div>
                <motion.div
                  className="flex gap-4 mb-6 items-center"
                  animate={inView ? "show" : "hidden"}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                >
                  <div
                    style={{
                      backgroundColor: homepage.habitBackgroundColor,
                      borderRadius: "50%",
                      padding: ".25rem",
                    }}
                  >
                    <RulesIconHomepage color={homepage.habitIconColor} />
                  </div>
                  <p className="text-sm max-w-[40ch]">
                    <span className="font-extrabold mb-1 block uppercase text-base">
                      WISSEN
                    </span>
                    Lerne Neues durch Workshops, Artikel oder Vorträge.
                  </p>
                </motion.div>
                <motion.div
                  className="flex gap-4 items-center"
                  animate={inView ? "show" : "hidden"}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                >
                  <div
                    style={{
                      backgroundColor: homepage.habitBackgroundColor,
                      borderRadius: "50%",
                      padding: ".25rem",
                    }}
                  >
                    <ReflectionIcon color={homepage.habitIconColor} />
                  </div>
                  <p className="text-sm max-w-[40ch]">
                    <span className="font-extrabold mb-1 block uppercase text-base">
                      REFLEXION
                    </span>
                    Reflektiere Dich und Dein Leben mit drei inspirierenden
                    wöchentlichen Fragen.
                  </p>
                </motion.div>
              </div>
            </div>

            <button
              className="relative z-10 bg-pink w-full block text-white font-extrabold py-4 px-8 rounded-full w-fit mx-auto mt-6"
              onClick={() => (window.location = "/register")}
              style={{
                backgroundColor: homepage.globalButtonColor,
                color: homepage.globalButtonTextColor,
              }}
            >
              {homepage.globalButtonText}
            </button>
          </section>

          <section className="text-center mb-12">
            <h2 className="text-3xl uppercase font-extrabold px-6 text-center mb-8">
              WAS WIR MIT HiLife SCHON ERREICHT HABEN
            </h2>

            <div className="py-3 px-6 border-b-2 border-gray-100">
              <p className="font-extrabold">
                <b>BESSERE REGENERATION</b> durch
                <span className="text-beige-dark">+ 80.000</span> Stunden guten
                Schlaf
              </p>
            </div>
            <div className="py-3 px-6 border-b-2 border-gray-100">
              <p className="font-extrabold">
                <b>TRAUMKÖRPER & GESUNDHEIT</b> durch{" "}
                <span className="text-beige-dark">+ 34.000</span> gesunde
                Mahlzeiten
              </p>
            </div>
            <div className="py-3 px-6 border-b-2 border-gray-100">
              <p className="font-extrabold">
                <b>FITNESS & ENERGIE</b> durch{" "}
                <span className="text-beige-dark">+ 172.000</span> Minuten
                Bewegung
              </p>
            </div>
            <div className="py-3 px-6 border-b-2 border-gray-100">
              <p className="font-extrabold">
                <b>RESILIENZ & IMMUNSYSTEM</b> durch{" "}
                <span className="text-beige-dark">+ 11.000</span> kalte Duschen
              </p>
            </div>
            <div className="py-3 px-6 border-b-2 border-gray-100">
              <p className="font-extrabold">
                <b>MENTALE GESUNDHEIT & STRESSABBAU</b> durch{" "}
                <span className="text-beige-dark">+ 17.000</span> Stunden in der
                Natur
              </p>
            </div>
            <div className="py-3 px-6 border-b-2 border-gray-100">
              <p className="font-extrabold">
                <b>RICHTIG GUTE LAUNE</b> durch{" "}
                <span className="text-beige-dark">+ 34.000</span>{" "}
                Dankbarkeitseinträge
              </p>
            </div>
            <div className="py-3 px-6">
              <p className="font-extrabold">
                <b>DIE LIFESTYLE REVOLUTION</b> durch{" "}
                <span className="text-beige-dark">+</span> über 500.000 Habits
                für ein besseres Leben
              </p>
            </div>
          </section>

          <section className="mb-12">
            <h3 className="uppercase font-extrabold text-center text-3xl">
              DIE APP
            </h3>
            <img src={app} alt="" className="w-96 mx-auto mb-6" />
            <div className="bg-beige flex p-6 mb-4">
              <div className="flex gap-4 max-w-lg items-center mx-auto">
                <p className="flex-1 text-sm md:text-base">
                  <b>SPAß:</b> Belohne Dich mit einem täglichen Dopamin-Hit beim
                  Abhaken der Habits in der HiLife Challenge WebApp
                </p>
                <CheckIconHome />
              </div>
            </div>
            <div className="bg-beige flex p-6 mb-4">
              <div className="flex gap-4 max-w-lg items-center mx-auto">
                <p className="flex-1 text-sm md:text-base">
                  <b>MOTIVATION:</b> Sammle und verfolge Deine Punkte in Deinem
                  Dahsboard und der Habit-Analyse und hole Dir die Krone für 80%
                  Einsatz
                </p>
                <CheckIconHome />
              </div>
            </div>
            <div className="bg-beige flex p-6 mb-4">
              <div className="flex gap-4 max-w-lg items-center mx-auto">
                <p className="flex-1 text-sm md:text-base">
                  <b>GEMEINSCHAFT:</b> Gründe ein Team und spiele zusammen mit
                  Freund*innen oder Arbeitskolleg*innen
                </p>
                <CheckIconHome />
              </div>
            </div>
            <div className="bg-beige flex p-6 mb-4">
              <div className="flex gap-4 max-w-lg items-center mx-auto">
                <p className="flex-1 text-sm md:text-base">
                  <b>SPANNUNG:</b> Vergleiche Dich mit der Community im
                  Leaderboard
                </p>
                <CheckIconHome />
              </div>
            </div>
            <div className="bg-beige flex p-6">
              <div className="flex gap-4 max-w-lg items-center mx-auto">
                <p className="flex-1 text-sm md:text-base">
                  <b>WISSEN:</b> Erweitere Dein Wissen mit der großen
                  Wissensdatenbank voller Blogartikel, wöchentlichen Live
                  Workshops und Vorträgen von Expert*innen
                </p>
                <CheckIconHome />
              </div>
            </div>
          </section>

          <section className="px-6 py-16 bg-beige text-center mb-12">
            <h3 className="uppercase font-extrabold mb-6 text-3xl">
              DEIN LIFESTYLE SCORE
            </h3>
            <p className="max-w-lg mx-auto text-sm md:text-base">
              Viele Denken, Gesundheit betrifft nur Ernährung und Sport. Dabei
              ist unser körperliches und mentales Wohlbefinden von so viel mehr
              Faktoren abhängig.
            </p>
            <div
              className="flex gap-4 justify-center my-6 flex-nowrap"
              ref={scoreRef}
            >
              <motion.div
                className="max-w-[175px] w-full"
                initial="hidden"
                animate={scoreInView ? "show" : "hidden"}
                variants={fadeIn}
                transition={{ duration: 1, when: "beforeChildren" }}
              >
                <Score total={100} score={78} points />
                <p className="font-extrabold uppercase text-2xl mt-2">Tag 1</p>
              </motion.div>
              <motion.div
                className="max-w-[175px] w-full"
                initial="hidden"
                animate={scoreInView ? "show" : "hidden"}
                variants={fadeIn}
                transition={{ duration: 1, when: "beforeChildren" }}
              >
                <Score placeholder />
                <p
                  className="font-extrabold uppercase text-2xl mt-2"
                  style={{ color: "#d3d3d3" }}
                >
                  Tag 28
                </p>
              </motion.div>
            </div>
            <p className="max-w-lg mx-auto text-sm md:text-base">
              Ermittle vor und nach der Challenge Deinen persönlichen Lifestyle
              Score um Deinen Fortschritt messbar zu machen - für echte
              Ergebnisse schwarz auf weiß.
            </p>
          </section>

          <section className="relative  mx-auto mb-24">
            <h2 className="max-w-lg mx-auto text-center text-3xl uppercase font-extrabold mb-6 z-10 relative">
              ÜBER UNS
            </h2>
            <img
              src={aboutDesktop}
              alt=""
              className="aspect-video object-cover mx-auto mb-8 rounded-lg z-10 relative"
            />
            <p className="max-w-xl mx-auto text-center mb-6 z-10 relative font-extrabold">
              Seit 2011 betreiben Lea und Leo Löhr gemeinsam myleo in Berlin.
            </p>
            <p className="max-w-xl mx-auto text-center z-10 relative pb-12 text-sm md:text-base">
              Als Sportwissenschaftler und Trainer hat Leo über 20.000 Coaching
              Stunden und mehr als 25 Jahre Erfahrung. Er ist als Gründer von
              myleo nicht nur ein Urgestein der CrossFit Szene und als Sieger
              des German Throwdowns 2022 in der Kategorie 45+ immer noch
              verdammt fit -, Leo weiß auch, wie man den eigenen Schweinehund am
              besten im Zaum hält. Motivation war schon vor knapp 20 Jahren das
              Thema seiner Diplomarbeit und lässt ihn bis heute nicht mehr los.
              <br />
              <br />
              Lea ist Juristin und organisiert und betreut seit über 10 Jahren
              Gesundheits- und Ernährungschallenges für ihre Mitglieder und
              Firmen. Das Thema Ernährung ist ihre Leidenschaft und mit dem
              Precision Nutrition L1 Zertifikat, CrossFit Nutrition, der
              Optimize Me Nutrition 3 Pillars Method Masterclass und jahrelanger
              Recherche und Weiterbildung hat sie ein umfangreiches Wissen
              aufgebaut. Mit ihrem außerordentlich großen Talent für Worte
              kümmert sich Lea darum, wissenschaftliche Erkenntnisse in
              verständliche Form zu bringen und sorgt für die Inhalte der HiLife
              Challenge.
            </p>
          </section>

          <section className="mb-12 our-experts">
            <h3 className="uppercase font-extrabold mb-6 text-3xl px-6 text-center">
              UNSERE EXPERT*INNEN DER LETZTEN CHALLENGES
            </h3>
            <p className="max-w-lg mx-auto text-center mb-12 px-6 text-sm md:text-base">
              Für unsere exklusiven Online Workshops und Webinare arbeiten wir
              mit Profis und <i>Expert*innen</i> aus diversen Branchen zusammen.{" "}
              <br />
              <br />
              Egal ob Ernährungsvortrag, Stress-Seminar, Kochkurs oder
              Atemmeditation: Du wirst jede Woche aufs Neue inspiriert und
              erweiterst Deinen Horizont. Hier ein paar unserer bisherigen
              Expert*innen.
            </p>

            <Swiper
              spaceBetween={20}
              slidesPerView={"auto"}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              navigation
              pagination={{ clickable: true }}
            >
              <SwiperSlide className="mb-8">
                <img src={matthias} alt="Andrea" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">
                  Dr. Matthias Hammer
                </p>
                <p className="text-center mb-2 text-sm">
                  Psychologe und Buchautor
                </p>
                <p className="text-center text-sm">
                  "Wie werde ich schädliche Gewohnheiten los und wie etabliere
                  ich langfristig neue, gute Gewohnheiten? Darüber hat der
                  Psychologe und mehrfache Buchautor Dr. Hammer sein neuestes
                  Buch „Micro Habits“ geschrieben."
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <img src={josephine} alt="Andrea" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">
                  Dr. Josephine Worseck
                </p>
                <p className="text-center mb-2 text-sm">
                  Molekularbiologin, Wim Hof Trainerin und Yogalehrerin
                </p>
                <p className="text-center text-sm">
                  "Nach ihrem Studium der Molekularbiologie, diversen
                  Ausbildungen zur Yogalehrerin, staatlich anerkannten
                  Heilpraktikerin und Wim Hof Trainerin ist Josephine
                  mittlerweile eine Expertin auf dem Gebiet der Stressreduktion.
                  Im Jahr 2019 veröffentlichte sie ihr Buch die „Heilkraft der
                  Kälte“."
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <img src={johannes} alt="Andrea" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">
                  Johannes Sartor
                </p>
                <p className="text-center mb-2 text-sm">Schlafexperte</p>
                <p className="text-center text-sm">
                  "Johannes ist Schlafcoach und Mitbegründer von Schlafonaut –
                  einem Online Portal für gesunden Schlaf. Zusammen mit Fabian
                  Dittrich betreibt er dazu den größten deutschen Youtube Kanal
                  zum Thema Schlaf und hat einen eigenen Podcast „Level Up Your
                  Sleep!"
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <img src={jacob} alt="Andrea" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">
                  Jacob Drachenberg
                </p>
                <p className="text-center mb-2 text-sm">
                  Stress Experte und Psychologe
                </p>
                <p className="text-center text-sm">
                  "Jacob ist Psychologe & Stress-Coach und als ehemaliger
                  Wasserball-Profi auch eine ziemliche Sportskanone . Er ist CEO
                  der Drachenberg-Akademie, die Vorträge und Seminare für
                  Privat- und Unternehmenskunden sowie Ausbildungen zum
                  Stress--Coach anbietet."
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <img src={dirk} alt="Andrea" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">
                  Dr. Dirk Lemke “Bill”
                </p>
                <p className="text-center mb-2 text-sm">Ernährungsmediziner</p>
                <p className="text-center text-sm">
                  "Doc Bill ist der absolute Experte auf dem Gebiet Ernährung.
                  Er ist Ernährungsmediziner, Fachreferent für Vitamin D und
                  praktiziert nach langjähriger Tätigkeit in der Neurologie seit
                  2018 als zertifizierter Coimbraprotokollarzt und
                  Liebscher-Bracht-Schmerzspezialist in der Praxis Schweiger in
                  Waldkirch bei Freiburg."
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <img src={ulrich} alt="Andrea" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">
                  Dr. Ulrich Ott
                </p>
                <p className="text-center mb-2 text-sm">
                  Psychologe und Wissenschaftler
                </p>
                <p className="text-center text-sm">
                  "Dr. Ulrich Ott ist Meditationsforscher an der Universität
                  Freiburg am Institut für Grenzgebiete der Psychologie und
                  Psychohygiene und dem Bender Institute of Neuroimaging. Als
                  Wissenschaftler kennt sich Dr. Ott bestens mit der Wirksamkeit
                  und Wirkungsweise von Meditation und Atmung aus."
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <img src={vidya} alt="Andrea" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">
                  Vidya & Klaus Ulbricht
                </p>
                <p className="text-center mb-2 text-sm">Atmungsexpert*innen</p>
                <p className="text-center text-sm">
                  "Vidya und Klaus C. Ulbricht sind die Gründer der naou School
                  of Consciousness und die Erfinder der Lichtatmung. Dabei
                  bringt Klaus 25 Jahre Entrepreneurship, Design und
                  Healing-Arts und Vidya den Atem und 30 Jahre
                  Meditationserfahrung mit. "
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <img src={slatco} alt="Andrea" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">
                  Slatco Sterzenbach
                </p>
                <p className="text-center mb-2 text-sm">
                  Motivations und Transformationscoach
                </p>
                <p className="text-center text-sm">
                  "Slatco hat 17 mal am legendären IRONMAN teilgenommen und ist
                  Gründer der umfangreichsten Online-Akademie in der
                  Persönlichkeitsentwicklung in Deutschland, um Menschen zu
                  unterstützen, die sich verändern und ihr Potenzial nutzen
                  wollen. Er kann auf 1.900 erfolgreiche Vortrags- und
                  Seminartage in 15 Ländern zurückblicken. "
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <img src={patrick} alt="Andrea" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">
                  Patrick Harithi
                </p>
                <p className="text-center mb-2 text-sm">Koch</p>
                <p className="text-center text-sm">
                  "Pat ist seit 15 Jahren Küchenchef im Caras Gourmetcafé in
                  Berlin und hat richtig was drauf, wenn es um gesundes und
                  leckeres Essen geht, das schnell und simpel zuzubereiten ist.
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <img src={leon} alt="Andrea" className="mx-auto mb-6" />
                <p className="font-extrabold text-center text-lg">
                  Leon Benedens
                </p>
                <p className="text-center mb-2 text-sm">
                  Mitbegründer und CEO von Fairment
                </p>
                <p className="text-center text-sm">
                  "2015 gründeten Leon Benedens und Paul Seelhorst fairment mit
                  der Vision, lebendige Nahrungsmittel zurück in unsere Küchen
                  zu bringen. Mittlerweile arbeitet das neunköpfige Team in
                  enger Kooperation mit den Delphin-Werkstätten im Herzen Berlin
                  Pankows. "
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <img
                  src={albrecht}
                  alt="Dr. Albrecht Vorster"
                  className="mx-auto mb-6"
                />
                <p className="font-extrabold text-center text-lg">
                  Dr. Albrecht Vorster
                </p>
                <p className="text-center mb-2 text-sm">Schlafexperte</p>
                <p className="text-center text-sm">
                  "Dr. Vorster ist Leiter des Swiss Sleep House Bern des
                  Universitätsklinikums Inselspital Bern und forscht an der
                  Verbesserung des Schlafes von Krankenhauspatienten sowie
                  Sportler*innen."
                </p>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <img
                  src={patrickheizmann}
                  alt="Patrick Heizmann"
                  className="mx-auto mb-6"
                />
                <p className="font-extrabold text-center text-lg">
                  Patrick Heizmann
                </p>
                <p className="text-center mb-2 text-sm">Gesundheitsexperte</p>
                <p className="text-center text-sm">
                  "Patrick ist fünffacher Bestsellerautor und als Fitness- und
                  Ernährungsexperte und Kolumnist für diverse Magazine,
                  Zeitschriften, Zeitungen, TV- und Radiosender tätig."
                </p>
              </SwiperSlide>
            </Swiper>
          </section>

          <section className="bg-beige py-12 our-press">
            <h3 className="uppercase font-extrabold mb-6 text-3xl px-6 text-center">
              PRESSESTIMMEN
            </h3>

            <Swiper
              spaceBetween={20}
              slidesPerView={"auto"}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              navigation
              pagination={{ clickable: true }}
            >
              <SwiperSlide className="mb-8">
                <div className="bg-white border p-10 rounded-lg shadow-lg">
                  <img
                    src={spiegel}
                    alt="Andrea"
                    className="mx-auto mb-6 aspect-[2/1] h-[70px] object-contain"
                  />
                  <p className="font-extrabold text-center text-lg">
                    Der Tagesspiegel
                  </p>
                  <p className="text-center mb-2 text-sm">Mai 2014</p>
                  <p className="text-center text-sm mb-4">
                    "CrossFit: ein wirklich hartes Geschäft"
                  </p>
                  <a
                    className="relative z-10 text-pink block text-white font-extrabold py-4 px-8 w-full text-center uppercase"
                    href="https://www.tagesspiegel.de/wirtschaft/fitness-trend-crossfit-ein-wirklich-hartes-geschaeft/9838378.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    lesen
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <div className="bg-white border p-10 rounded-lg shadow-lg">
                  <img
                    src={mens}
                    alt="Andrea"
                    className="mx-auto mb-6 aspect-[2/1] h-[70px] object-contain"
                  />
                  <p className="font-extrabold text-center text-lg">
                    Men's Health
                  </p>
                  <p className="text-center mb-2 text-sm">Juni 2019</p>
                  <p className="text-center text-sm mb-4">
                    "Mehr Power mit CrossFit Training"
                  </p>
                  <a
                    className="relative z-10 text-pink block text-white font-extrabold py-4 px-8 w-full text-center uppercase"
                    href="https://www.menshealth.de/krafttraining/mehr-kraft-und-muskeln-mit-crossfit-training/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    lesen
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <div className="bg-white border p-10 rounded-lg shadow-lg">
                  <img
                    src={deutschlandfunk}
                    alt="Andrea"
                    className="mx-auto mb-6 aspect-[2/1] h-[70px] object-contain"
                  />
                  <p className="font-extrabold text-center text-xs">
                    Deutschlandfunk Kultur
                  </p>
                  <p className="text-center mb-2 text-sm mt-3">August 2016</p>
                  <p className="text-center text-sm mb-4">
                    "Schuften gegen den Schweinehund"
                  </p>
                  <a
                    className="relative z-10 text-pink block text-white font-extrabold py-4 px-8 w-full text-center uppercase"
                    href="https://www.deutschlandfunkkultur.de/crossfit-training-schuften-gegen-den-schweinehund-100.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    lesen
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <div className="bg-white border p-10 rounded-lg shadow-lg">
                  <img
                    src={deutschwelle}
                    alt="Andrea"
                    className="mx-auto mb-6 aspect-[2/1] h-[70px] object-contain"
                  />
                  <p className="font-extrabold text-center text-lg">
                    Deutsche Welle
                  </p>
                  <p className="text-center mb-2 text-sm">August 2016</p>
                  <p className="text-center text-sm mb-4">
                    "Hartes Training. Großer Erfolg"
                  </p>
                  <a
                    className="relative z-10 text-pink block text-white font-extrabold py-4 px-8 w-full text-center uppercase"
                    href="https://www.dw.com/de/hartes-training-gro%C3%9Fer-erfolg-crossfit/a-19166825"
                    target="_blank"
                    rel="noreferrer"
                  >
                    lesen
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <div className="bg-white border p-10 rounded-lg shadow-lg">
                  <img
                    src={weltbrand}
                    alt="Andrea"
                    className="mx-auto mb-6 aspect-[2/1] h-[70px] object-contain"
                  />
                  <p className="font-extrabold text-center text-lg">Welt</p>
                  <p className="text-center mb-2 text-sm">April 2017</p>
                  <p className="text-center text-sm mb-4">
                    "So steigen sie ins CrossFit ein"
                  </p>
                  <a
                    className="relative z-10 text-pink block text-white font-extrabold py-4 px-8 w-full text-center uppercase"
                    href="https://www.welt.de/sport/video163485346/So-steigen-Sie-ins-CrossFit-ein.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    lesen
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <div className="bg-white border p-10 rounded-lg shadow-lg">
                  <img
                    src={achilles}
                    alt="Andrea"
                    className="mx-auto mb-6 aspect-[2/1] h-[70px] object-contain"
                  />
                  <p className="font-extrabold text-center text-lg">
                    Achilles Running
                  </p>
                  <p className="text-center mb-2 text-sm">April 2021</p>
                  <p className="text-center text-sm mb-4">
                    "Achilles Running: CrossFit"
                  </p>
                  <a
                    className="relative z-10 text-pink block text-white font-extrabold py-4 px-8 w-full text-center uppercase"
                    href="https://www.achilles-running.de/crossfit/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    lesen
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <div className="bg-white border p-10 rounded-lg shadow-lg">
                  <img
                    src={engelvoelker}
                    alt="Andrea"
                    className="mx-auto mb-6 aspect-[2/1] h-[70px] object-contain"
                  />
                  <p className="font-extrabold text-center text-lg">
                    Engel und Völkers
                  </p>
                  <p className="text-center mb-2 text-sm">April 2017</p>
                  <p className="text-center text-xs mb-6">
                    "Warum Engel und Völkers auf ein Power Workout setzt"
                  </p>
                  <a
                    className="relative z-10 text-pink block text-white font-extrabold py-4 px-8 w-full text-center uppercase"
                    href="https://www.engelvoelkers.com/de-de/berlincommercial/blog/interview-warum-engel--v%C3%B6lkers-auf-ein-power-workout-setzt/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    lesen
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide className="mb-8">
                <div className="bg-white border p-10 rounded-lg shadow-lg">
                  <img
                    src={vice}
                    alt="Andrea"
                    className="mx-auto mb-6 aspect-[2/1] h-[70px] object-contain"
                  />
                  <p className="font-extrabold text-center text-lg">Vice</p>
                  <p className="text-center mb-2 text-sm">Oktober 2011</p>
                  <p className="text-center text-sm mb-4">
                    "Trainieren bis zum Kotzen?"
                  </p>
                  <a
                    className="relative z-10 text-pink block text-white font-extrabold py-4 px-8 w-full text-center uppercase"
                    href="https://www.vice.com/de/article/wdyjny/trainieren-bis-zum-kotzen"
                    target="_blank"
                    rel="noreferrer"
                  >
                    lesen
                  </a>
                </div>
              </SwiperSlide>
            </Swiper>
          </section>

          <section className="relative flex flex-col justify-center aspect-square lg:aspect-video lg:max-h-[80vh] w-full bg-beige">
            <img
              alt=""
              src={homepage.footerImage}
              className="absolute top-0 left-0 w-full h-full object-cover"
            />

            <h2 className="relative z-10 text-center text-white font-extrabold mb-6 text-3xl lg:text-7xl lg:mb-10 px-6">
              {homepage.footerText}
            </h2>
            <button
              className="relative z-10 bg-pink w-full block text-white font-extrabold py-4 px-8 rounded-full w-fit mx-auto"
              onClick={() => (window.location = "/register")}
              style={{
                backgroundColor: homepage.globalButtonColor,
                color: homepage.globalButtonTextColor,
              }}
            >
              {homepage.globalButtonText}
            </button>
          </section>

          <Footer />
        </div>
      )}
    </div>
  );
}

export default Home;
