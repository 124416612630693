import React, { useState, useEffect } from "react";
import { FaComments, FaChevronDown, FaUserSlash } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./ChallengeGlobalSettings.scss";
import SearchUser from "../SearchUser/SearchUser";
import axios from "axios";

const ChallengeGlobalSettings = ({ globalSettings, onSettingsChange }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [hiddenUsers, setHiddenUsers] = useState([]);

  useEffect(() => {
    if (activeSection === "hiddenUsers") {
      fetchHiddenUsers();
    }
  }, [activeSection]);

  const fetchHiddenUsers = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/challenge/${globalSettings.challengeId}/hidden-users`
      );
      setHiddenUsers(response.data);
    } catch (error) {
      console.error("Error fetching hidden users:", error);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  const handleInputChange = (section, field, value) => {
    onSettingsChange({
      ...globalSettings,
      [section]: {
        ...globalSettings[section],
        [field]: value,
      },
    });
  };

  return (
    <div className="challenge-global-settings">
      {/* Community Chat Settings */}
      <div
        className={`settings-section ${
          activeSection === "communityChat" ? "active" : ""
        }`}
      >
        <div
          className="section-header"
          onClick={() =>
            setActiveSection(
              activeSection === "communityChat" ? null : "communityChat"
            )
          }
        >
          <div className="section-title">
            <FaComments />
            <h3>Community Chat Settings</h3>
          </div>
          <FaChevronDown
            className={`section-toggle ${
              activeSection === "communityChat" ? "active" : ""
            }`}
          />
        </div>

        {activeSection === "communityChat" && (
          <div className="section-content">
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                value={globalSettings?.communityChat?.title || ""}
                onChange={(e) =>
                  handleInputChange("communityChat", "title", e.target.value)
                }
                placeholder="Enter title"
              />
            </div>

            <div className="form-group">
              <label>Subtitle</label>
              <input
                type="text"
                value={globalSettings?.communityChat?.subtitle || ""}
                onChange={(e) =>
                  handleInputChange("communityChat", "subtitle", e.target.value)
                }
                placeholder="Enter subtitle"
              />
            </div>

            <div className="form-group">
              <label>Content</label>
              <ReactQuill
                value={globalSettings?.communityChat?.content || ""}
                onChange={(value) =>
                  handleInputChange("communityChat", "content", value)
                }
                modules={modules}
                placeholder="Enter content with formatting..."
                className="rich-text-editor"
              />
            </div>
          </div>
        )}
      </div>

      {/* Chat View Settings */}
      <div
        className={`settings-section ${
          activeSection === "chatView" ? "active" : ""
        }`}
      >
        <div
          className="section-header"
          onClick={() =>
            setActiveSection(activeSection === "chatView" ? null : "chatView")
          }
        >
          <div className="section-title">
            <FaComments />
            <h3>Chat View Settings</h3>
          </div>
          <FaChevronDown
            className={`section-toggle ${
              activeSection === "chatView" ? "active" : ""
            }`}
          />
        </div>

        {activeSection === "chatView" && (
          <div className="section-content">
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                value={globalSettings?.chatView?.title || ""}
                onChange={(e) =>
                  handleInputChange("chatView", "title", e.target.value)
                }
                placeholder="Enter title"
              />
            </div>

            <div className="form-group">
              <label>Content</label>
              <ReactQuill
                value={globalSettings?.chatView?.content || ""}
                onChange={(value) =>
                  handleInputChange("chatView", "content", value)
                }
                modules={modules}
                placeholder="Enter content with formatting..."
                className="rich-text-editor"
              />
            </div>
          </div>
        )}
      </div>

      {/* Hidden Users */}
      <div
        className={`settings-section ${
          activeSection === "hiddenUsers" ? "active" : ""
        }`}
      >
        <div
          className="section-header"
          onClick={() =>
            setActiveSection(
              activeSection === "hiddenUsers" ? null : "hiddenUsers"
            )
          }
        >
          <div className="section-title">
            <FaUserSlash />
            <h3>Hidden Users</h3>
          </div>
          <FaChevronDown
            className={`section-toggle ${
              activeSection === "hiddenUsers" ? "active" : ""
            }`}
          />
        </div>

        {activeSection === "hiddenUsers" && (
          <div className="section-content">
            <SearchUser
              challengeId={globalSettings.challengeId}
              onUserToggle={async (userId, hidden) => {
                try {
                  await axios.put(
                    `/api/challenge/${globalSettings.challengeId}/user/${userId}/hidden`,
                    { hidden }
                  );
                  fetchHiddenUsers();
                } catch (error) {
                  console.error("Error updating user visibility:", error);
                }
              }}
            />

            {hiddenUsers.length > 0 && (
              <div className="hidden-users-list">
                {hiddenUsers.map((user) => (
                  <div key={user._id} className="hidden-user-item">
                    <div className="user-info">
                      {user.avatar ? (
                        <img src={user.avatar} alt="" className="user-avatar" />
                      ) : (
                        <div className="user-avatar-placeholder">
                          {user.firstname[0]}
                        </div>
                      )}
                      <span>
                        {user.firstname} {user.lastname}
                      </span>
                    </div>
                    <button
                      onClick={async () => {
                        try {
                          await axios.put(
                            `/api/challenge/${globalSettings.challengeId}/user/${user._id}/hidden`,
                            { hidden: false }
                          );
                          fetchHiddenUsers();
                        } catch (error) {
                          console.error(
                            "Error updating user visibility:",
                            error
                          );
                        }
                      }}
                      className="unhide-button"
                    >
                      <FaUserSlash />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChallengeGlobalSettings;
